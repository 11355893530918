.App {
  /* text-align: center; */
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
}

body{
  overflow-x: hidden;
}

@media screen and (max-width: 700px){ html,body {min-width:700px} }

/* ::-webkit-scrollbar {
  width: 0px;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
:root{
--caBlue: #009AF9;
--steelGrey: #727272;
--successGreen: #00B283;
--successGreenlight: #D0F1E8;
--warningYellow: #F2CB66;
--warningYellowlight: #FFF2D8;
--errorRed: #DC3233;
--errorRedlight: #F4E7E7;
--actionBlue: #0078C2;
--actionBluelight: #EDF8FF;
--offWhite: #F8FAFB;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@import url('https://fonts.googleapis.com/css?family=Montserrat');

@import url('https://fonts.googleapis.com/css?family=Oxygen');

@import url('https://fonts.googleapis.com/css?family=Nunito');

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Open Sans';
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar{
  background-color: white;
  width: '100%';
}


button.caBtn {
  background-color: var(--caBlue);
  color: white
}

button.bwBtn {
  background-color:white;
  color:black;
  font-weight: 700;
}

button.navBtn {
  padding-top: 12.5px;
  padding-left:20px;
  padding-right:20px;
  padding-bottom:12.5px;
  margin:25px;
  border:0px;
  border-radius: 6px;
  font-size:medium;
  font-family: 'Montserrat';
  opacity: 0.8;
  letter-spacing:1px;

  
}

.footer_word {
  font-size: 5;
}

.footer{
  /* margin-top: 1rem;
  padding: 1rem;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0; */
  width: 100%;
}

.create-shape-btn {
  display: flex;
  width: 432px;
  height: 50px;
  margin: 20px 20px 10px;
  padding-right: 10px;
  padding-left: 10px;
  align-items: center;
  border: 1px solid #009af9;
  border-radius: 6px;
  background-color: #fff;
  color: #009af9;
}

.div-block-1 {
  display: flex;
  height: 80%;
  justify-content: space-around;
  background-color: #f8fafb;
  object-fit: contain;
}

.div-block-2 {
  display: flex;
  width: 70%;
  object-fit: contain;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: "#98A9BC";
}

/* Removing the default arrow */
/* https://stackoverflow.com/questions/65120873/how-to-add-padding-to-the-default-arrow-in-a-select-dropdown-list */
.enterDetails{
  -webkit-appearance: none;
  appearance: none;
}

/* Custom arrow */
.enterDetails{
  background-image: url("../public/dropArrow.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
}

/* https://www.tutorialrepublic.com/faq/how-to-remove-outline-around-text-input-boxes-in-chrome-using-css.php */
/* https://stackoverflow.com/questions/43820420/change-the-border-color-of-the-select-input-for-focus */
.enterDetails:focus {
  outline: none;
}

.ck-editor__editable {
  min-height: 500px;
}
